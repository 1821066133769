import React, { useMemo } from "react"
import { graphql, PageProps } from "gatsby"
import { ComoAcademyPageProps } from "../types/pages"
import PageLayout from "../layouts/page-layout"
import ServicePageLayout from "../layouts/service-page-layout"

const ComoAcademy: React.FC<PageProps<ComoAcademyPageProps>> = ({ data }) => {
  const { banner, comoAcademyContent, comoacademyAcc } =
    data?.PAGE_CONTENT?.comoAcademyOptions

  const accordions = useMemo(() => {
    return comoacademyAcc?.map(acc => {
      return {
        title: acc.title,
        description: acc.description,
      }
    })
  }, [JSON.stringify(comoacademyAcc)])

  return (
    <PageLayout seo={data?.PAGE_METADATA?.seo}>
      <ServicePageLayout
        banner={banner}
        pageTitle={data?.PAGE_METADATA?.title}
        content={comoAcademyContent}
        accordions={accordions}
      />
    </PageLayout>
  )
}

export const query = graphql`
  query ($uri: String!, $locale: String!) {
    PAGE_METADATA: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      id
      slug
      title
      link
      locale {
        locale
      }
      seo {
        metaDesc
        metaKeywords
        canonical
        opengraphDescription
        opengraphTitle
        opengraphType
        title
        twitterDescription
        twitterTitle
      }
    }
    PAGE_CONTENT: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      comoAcademyOptions {
        banner {
          altText
          sizes
          sourceUrl
          srcSet
        }
        comoAcademyContent
        comoacademyAcc {
          description
          title
        }
      }
    }
  }
`

export default ComoAcademy
